import { FlowType, flowTypeEnumToString, LanguageCode, OtpType, languageCodeStringToEnum } from "@/enums";
import { loading, replaceState, getTranslations, translation } from "@/utils/common";
import { CheckoutInfo, OtpResponse, TextResourceList } from "@/entities";
import { getTextResources } from "tests/entities";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { otpKey } from "@/store";
import { MaskaDetail } from "maska";
import { ModalComponent } from "@/components";

export default defineComponent({
    name: "DateOfBirthView",
    components: {
        ModalComponent,
    },
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new CheckoutInfo(),
            merchantReturnUrl: <string | null>"",
            birthDateIsValid: false,
            birthDateInputError: false,
            birthDateHintMessage: "",
            birthDateMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleBirthDateComplete(detail) : null),
                postProcess: (val: string) => this.handleBirthDateValidation(val),
            },
            textResourceList: new TextResourceList(),
            OtpType,
            requestCodeDisabled: true,
            store: useStore(otpKey),
            confirmExitOpen: false,
            birthDate: "",
        };
    },
    async created() {
        replaceState();
        this.data = this.store.state.otp;

        this.merchantReturnUrl = useRouter().currentRoute.value.query.merchantUrl as string;
        if (!this.merchantReturnUrl) {
            this.merchantReturnUrl = this.data.redirectUrl;
        }

        loading();
        try {
            const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}?merchantUrl=${this.merchantReturnUrl}`);

            if (response.data) {
                response.data.redirectUrl = this.merchantReturnUrl;
            }

            //redirect to error (or restart) view
            if (!response.isSuccess) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.merchantReturnUrl}`);
            }

            //redirect back to merchant
            if (this.merchantReturnUrl && response.returnToMerchant) {
                window.location.replace(this.merchantReturnUrl);
            }

            //set focus to birthDateInput
            document.getElementById("birthDateInput")?.focus();

            this.data = response.data ?? new CheckoutInfo();
            this.store.commit("setOtp", this.data);
        } catch (e) {
            console.log("Error while loading page data.", e);
        } finally {
            loading();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();

            try {
                this.textResourceList = (await this.getTextResources(this.data.countryCode, languageCodeStringToEnum(this.data.conversationLanguage), fetchFromTest, httpsAgent)) as TextResourceList;
                this.birthDateHintMessage = this.getTranslation("OtpDobHint");
            } catch {
                return;
            } finally {
                loading();
            }
        },
        hasSteps() {
            return this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob);
        },
        getBirthDatePlaceholder() {
            const languageCode = languageCodeStringToEnum(this.data.conversationLanguage);
            switch (languageCode) {
                case LanguageCode.NL:
                    return "DD/MM/JJJJ";
                case LanguageCode.DE:
                    return "TT/MM/JJJJ";
                default:
                    return "DD/MM/YYYY";
            }
        },
        handleBirthDateValidation(val: string) {
            this.birthDateHintMessage = this.getTranslation("OtpDobHint");
            this.birthDateInputError = false;
            this.birthDateIsValid = false;

            this.dateAndAgeValidationReturningBirthDate(val);

            return val;
        },
        handleBirthDateComplete(detail: MaskaDetail) {
            const btn = document.getElementById("continueBtn");
            if (!btn) return;

            this.birthDate = this.dateAndAgeValidationReturningBirthDate(detail.masked);

            if (!this.birthDateInputError) {
                this.birthDateIsValid = true;
                btn.removeAttribute("disabled");
            } else {
                btn.setAttribute("disabled", "true");
            }
        },
        dateAndAgeValidationReturningBirthDate(date: string) {
            const tempVal = date.replaceAll("/", "");
            const day = Number.parseInt(tempVal.substring(0, 2));
            const month = Number.parseInt(tempVal.substring(2, 4));
            const year = Number.parseInt(tempVal.substring(4, 8));

            if ((day > 31 || month > 12 || year > new Date().getFullYear() || year <= 1900) && date.length == 10) {
                this.birthDateInputError = true;
                this.birthDateHintMessage = this.getTranslation("OtpWrongBirthDate");
            }

            const birthDate = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
            this.ageValidation(birthDate);

            return birthDate;
        },
        ageValidation(date: string) {
            if (date.length == 10) {
                const birthDateTime = new Date(date).getTime();
                const currentTime = new Date().getTime();
                const difference = currentTime - birthDateTime;
                const age = difference / (1000 * 60 * 60 * 24 * 365);

                if (age > 120) {
                    this.birthDateInputError = true;
                    this.birthDateHintMessage = this.getTranslation("OtpWrongBirthDate");
                }
            }
        },
        confirmExit() {
            document.body.style.overflow = "hidden";
            this.confirmExitOpen = true;
        },
        closeModal() {
            document.body.style.overflow = "auto";
            this.confirmExitOpen = false;
            window.location.href = window.location.href.split("#")[0];
        },
        async goBack() {
            //change status to "UserCancel" and redirect to merchant
            const response = await get(`api/verifyotp/cancel/${this.scaSessionId}`);
            if (!response.isSuccess) {
                //redirect to error view
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
            }

            const urlParams = new URLSearchParams(window.location.search);
            const merchantUrl = urlParams.get("merchantUrl") ?? "https://www.riverty.com";

            window.location.replace(merchantUrl);
        },
        async validateDOB() {
            const response = await get(`api/verifyotp/verify-birth-date/${this.scaSessionId}/${this.birthDate}`);
            this.data = response.data;
            this.store.commit("setOtp", response.data);

            //redirect to error view
            if (!response.isSuccess) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.merchantReturnUrl}`);
            }

            //redirect back to merchant
            if (this.merchantReturnUrl && response.returnToMerchant) {
                window.location.replace(this.merchantReturnUrl);
            }

            //redirect to next step
            if (response.view) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.merchantReturnUrl}`);
            }
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "OtpBackButton",
                "OtpContinueButton",
                "OtpConfirmDobOnly",
                "OtpConfirmDobOnlyDescription",
                "OtpEnterYourDob",
                "OtpConfirmDob",
                "OtpDobLabel",
                "OtpDobHint",
                "OtpWrongBirthDate",
                "OtpClose",
                "OtpExitVerification",
                "OtpExitVerificationPrompt",
                "OtpExitVerificationDescription",
                "OtpContinueVerification",
            ];

            return await getTranslations(sysnameList, countryCode, languageCode, fetchFromTest, httpsAgent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
